.NavIcon_icon__mjroI {
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	width: 32px;
	height: 32px;
	padding: 0;
	line-height: 0;
	text-decoration: none;
	box-sizing: content-box;
	justify-content: center;
	align-items: center;
}
.NavIcon_icon__mjroI:hover {
	background: rgba(255,255,255,25%);
}
.NavIcon_active__RGqP8 {
	border-bottom: 4px solid white;
}

html,
body {
	box-sizing: border-box;
	height: 100%;
	padding: 0;
	margin: 0;
}
body {
	/* background-color: #FF6969; */
	background: url(/_next/static/media/background.74d90d60.svg) top center var(--is-light-mode, #990000) var(--is-dark-mode, #440000);
	background: url(/_next/static/media/background.74d90d60.svg) top center light-dark(#990000, #440000);
	min-height: 100%;
	display: flex;
	flex-direction: column;
	font-size: 16px;
}
body > * {
	flex-grow: 1;
}
strong {
	font-weight: bolder;
}

:root {
	--is-light-mode: initial;
	--is-dark-mode: ;
}

@media (prefers-color-scheme: dark) {
	:root {
		--is-light-mode: ;
		--is-dark-mode: initial;
	}
}

.Nav_nav__KUSwi {
	padding: 0 64px;
	column-gap: 16px;
	display: grid;
	grid-template:
		"logo title title" 48px
		"logo topics icons" 48px
		/ 256px 1fr auto;
	justify-content: center;
}
.Nav_pictureHolder__sKl8b {
	justify-self: center;
	grid-area: logo;
	align-self: end;
	height: 96px;
	font-size: 0;
}
.Nav_picture__fh_94 {
	border-radius: 50%;
	border: 8px solid #EEEEEE;
	margin-top: 10px;
	background: #88835e;
}
.Nav_title___sPoC {
	margin: 0;
	grid-area: title;
}
.Nav_spacer__NuTlD {
	width: 8px;
}
.Nav_icons__ZZkM9 {
	display: flex;
	gap: 4px;
	align-self: center;
	font-size: 0;
	grid-area: icons;
}
.Nav_icons__ZZkM9::after {
	content: '';
	height: 36px;
}
.Nav_topics__8ftst {
	align-self: flex-end;
	grid-area: topics;
	margin-bottom: 8px;
}
.Nav_topics__8ftst ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	font-size: 1.5em;
	gap: 8px;
}
.Nav_topics__8ftst ul::after {
	content: '\00a0';
	flex: 1;
	display: inline-block;
	color: inherit;
	text-decoration: none;
	border-bottom: 4px solid transparent;
}
.Nav_topic__Pbn37 a {
	display: inline-block;
	color: inherit;
	text-decoration: none;
	border-bottom: 1px solid #EEEEEE;
}
.Nav_topic__Pbn37 a:hover, .Nav_topic__Pbn37 a:focus-visible {
	background: #EEEEEE33;
}
.Nav_topicLast__vGu0Z {
	order: 1;
}
.Nav_active__TEg50.Nav_active__TEg50 {
	border-bottom: 4px solid #EEEEEE;
}
@media screen and (max-width: 1280px) {
	.Nav_nav__KUSwi {
		padding: 0 8px;
	}
}
@media screen and (max-width: 1024px) {
	.Nav_nav__KUSwi {
		grid-template:
			"title title title" 48px
			"logo _ _" 48px
			"logo topics icons" 48px
			/ 256px 1fr auto;
	}
	.Nav_title___sPoC {
		justify-self: center;
	}
}
@media screen and (max-width: 768px) {
	.Nav_nav__KUSwi {
		grid-template:
			"title title" auto
			"topics topics" auto
			"logo icons" 96px
			/ 256px 1fr;
	}
	.Nav_topics__8ftst {
		justify-self: center;
	}
	.Nav_icons__ZZkM9 {
		justify-self: flex-end;
		align-self: flex-end;
	}
}
@media screen and (max-width: 480px) {
	.Nav_nav__KUSwi {
		grid-template:
			"title" auto
			"topics" auto
			"logo" 96px
			/ 1fr;
	}
	.Nav_icons__ZZkM9 {
		grid-area: logo;
	}
	.Nav_topics__8ftst {
		justify-self: stretch;
	}
	.Nav_topics__8ftst ul {
		display: block;
	}
	.Nav_topic__Pbn37 a {
		display: block;
		height: 40px;
		box-sizing: border-box;
	}
	.Nav_topics__8ftst ul::after {
		content: initial;
	}
}

.layout_skipToContent__sfLRj {
	background: #e77e23;
	height: 30px;
	left: 50%;
	padding: 8px;
	position: absolute;
	transform: translateY(-100%);
	transition: transform 0.3s;
	z-index: 99;
}
.layout_skipToContent__sfLRj:focus-within {
	transform: translateY(0%);
}

.layout_bar___gjVu {
	flex-grow: 0;
	flex-shrink: 0;
	background-color: var(--is-light-mode, rgb(14, 23, 41)) var(--is-dark-mode, black);
	background-color: light-dark(rgb(14, 23, 41), black);
	color: #EEEEEE;
	position: relative;
	z-index: 1;
}
.layout_barTop__ow8_G {
	border-bottom: 8px solid var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	border-bottom: 8px solid light-dark(#FFFFFF, #111111);
	margin-bottom: 16px;
}
.layout_barBottom__uF_Cg {
	border-top: 8px solid var(--is-light-mode, #FFFFFF) var(--is-dark-mode, #111111);
	border-top: 8px solid light-dark(#FFFFFF, #111111);
	height: 96px;
}


/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/82ef96de0e8f4d8c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a6ecd16fa044d500-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/bd82c78e5b7b3fe9-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c32c8052c071fc42-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6f22fce21a7c433c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/1d8a05b60287ae6c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/77c207b095007c34-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c4250770ab8708b6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/82ef96de0e8f4d8c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a6ecd16fa044d500-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/bd82c78e5b7b3fe9-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c32c8052c071fc42-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6f22fce21a7c433c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/1d8a05b60287ae6c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/77c207b095007c34-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c4250770ab8708b6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/82ef96de0e8f4d8c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a6ecd16fa044d500-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/bd82c78e5b7b3fe9-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c32c8052c071fc42-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6f22fce21a7c433c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/1d8a05b60287ae6c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/77c207b095007c34-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c4250770ab8708b6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Noto Sans Fallback';src: local("Arial");ascent-override: 100.54%;descent-override: 27.56%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_52a310 {font-family: 'Noto Sans', 'Noto Sans Fallback';font-style: normal
}

